import { Controller } from "stimulus"
import Flickity  from 'flickity'

export default class extends Controller {
  // static targets = [ "slide", "dotList", "dot" ]

  connect() {
    this.swiper = new Flickity('.slideshow', {
      cellAliign: 'left',
      // contain: true,
      autoPlay: true,
    })
  }
}
