import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "menu" ]

  connect() {
    this.scrolled()
  }

  open(event) {
    event.currentTarget.classList.toggle('is-active')
    this.menuTarget.classList.toggle('is-active')
  }

  scrolled() {
    const currentScrollY = window.pageYOffset;
    if (currentScrollY > 600) {
      this.element.classList.add('is-visible')
    } else {
      this.element.classList.remove('is-visible')
    }
  }
}
