import { Controller } from "stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    if (this.mask) {
      this.addMask()
    }
  }


  // private

  addMask() {
    this.masker = new IMask(this.fieldTarget, this.maskOptions)
  }

  get maskOptions() {
    switch (this.mask) {
      case "currency":
        return {
          mask: 'R$ num',
          blocks: {
            num: {
              mask: Number,
              thousandsSeparator: '.',
              scale: 2,
              radix: ',',
              normalizeZeros: true,
              padFractionalZeros: true,
            }
          }
        }
        break;
      case "phone":
        return {
          mask: [ { mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' } ]
        }
        break;
      default:
        return { mask: this.mask }
    }
  }

  get mask() {
    return this.data.get("mask")
  }
}
